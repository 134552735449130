<template>
  <div
    align-v="center"
    style="display: flex; align-items: center; justify-content: center"
    class="p-5"
  >
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-img center src="@/assets/logo.jpeg" />
        </b-col>
        <div class="p-3" />
        <b-col
          cols="12"
          style="display: flex; align-items: center; justify-content: center"
        >
          <b-form center inline style="width: 60%" @submit.prevent="login">
            <b-container class="border border-success p-5">
              <b-row class="mb-3">
                <b-col>
                  <b-img center src="@/assets/apv_logo.png" />
                </b-col>
                <div class="w-100" />
                <b-col cols="5" />
                <b-col>
                  <div @click="changeLocale('fr')">
                    <flag iso="fr" />
                  </div>
                </b-col>
                <b-col>
                  <div @click="changeLocale('en')">
                    <flag iso="us" />
                  </div>
                </b-col>
                <b-col cols="5" />
                <div class="w-100 p-3" />
                <template v-if="api_version != 'v1'">
                  <b-col>
                    <label for="tenant">{{
                      $t("main.login.tenant.label")
                    }}</label>
                  </b-col>
                  <b-col>
                    <template v-if="tenantInput">
                      <b-form-input
                        id="tenant"
                        v-model="tenant"
                        :placeholder="$t('main.login.tenant.placeholder')"
                        required
                        style="width: 100%"
                      ></b-form-input
                    ></template>
                    <template v-else>
                      {{ tenant }}
                    </template>
                  </b-col>
                  <div class="w-100 p-1"></div>
                </template>
                <b-col>
                  <label for="username">{{
                    $t("main.login.username.label")
                  }}</label>
                </b-col>
                <b-col>
                  <b-form-input
                    id="username"
                    v-model="username"
                    :placeholder="$t('main.login.username.placeholder')"
                    required
                    style="width: 100%"
                  ></b-form-input>
                </b-col>
                <div class="w-100 p-1"></div>

                <b-col>
                  <label for="password">{{
                    $t("main.login.password.label")
                  }}</label>
                </b-col>

                <b-col>
                  <b-form-input
                    type="password"
                    v-model="password"
                    required
                    id="password"
                    style="width: 100%"
                  ></b-form-input>
                </b-col>
                <div class="w-100 p-3"></div>
                <b-col>
                  <b-button
                    type="submit"
                    variant="success"
                    style="width: 75%"
                    >{{ $t("main.login.submit") }}</b-button
                  >
                </b-col>
                <b-col>
                  <b-button
                    type="button"
                    variant="secondary"
                    style="width: 75%"
                    @click="makeToast('success')"
                    v-if="api_version != 'v1'"
                    >{{ $t("main.login.forgetpassword") }}</b-button
                  >
                </b-col>
                <div class="w-100 p-3"></div>
              </b-row>
            </b-container>
          </b-form>
        </b-col>
        <b-col cols="12">
          {{ $t("main.copyright") }}
        </b-col>
        <b-col cols="12" v-if="isDev">
          {{ host }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { AUTH_REQUEST } from "@/store/modules/auth/enums";
export default {
  data() {
    return {
      api_version: process.env.VUE_APP_API_VERSION || "v1",
      tenant:
        location.host.split(".").length >= 5 ? location.host.split(".")[0] : "",
      tenantInput: location.host.split(".").length < 5,
      username: "",
      password: "",
      host: location.host,
      isDev: process.env.NODE_ENV === "development",
    };
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t("main.login.error.msg"), {
        title: this.$t("main.login.error.title"),
        variant: variant || "danger",
        solid: true,
      });
    },
    login: function () {
      const username = this.username;
      const password = this.password;
      const tenant = this.tenant;
      this.$store
        .dispatch(AUTH_REQUEST, { tenant, username, password })
        .then(() => {
          const shops = this.$store.getters.user.shopIds;
          console.log("shops", shops);
          this.$store.dispatch("SHOPS_LOAD", shops);
          this.$router.push("/shops");
        })
        .catch(() => {
          this.makeToast("danger");
        });
    },
    changeLocale: function (locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>
